<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Regist Device Dialog"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">{{title}}</h6>
    </template>

    <div class='modal-body'>
      <form name='userForm'>
        <div class='form-group'>
          <div class='row'>
            <div class='col-md-12'>
              <label>디바이스명 <strong class='text-danger'>*<small>{{view_status}}</small></strong></label>
              <input type='text' class='form-control' placeholder='디바이스명' v-model='device.name' :disabled='true'>
            </div>
          </div>
        </div>
        <div class='form-group'>
          <div class='row'>
            <div class='col-md-12'>
              <label>IMEI <strong class='text-danger'></strong></label>
              <input type='text' class='form-control' placeholder='0123456789ABCDEF' v-model='imei'>
            </div>
          </div>
        </div>

      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info" :disabled="status==1">등록</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>
  </KModal>
</template>

<script>
import loopback from '@/services/loopback';

export default {
  name: 'RegistDevice',
  props: {
    isAdmin: Boolean
  },
  data () {
    return {
      showModal: false,
      title: '디바이스 등록',
      which: 'regist',
      device: {},
      imei: '',
      status: '',
      isEditMode: false
    }
  },
  computed: {
    view_status() {
      return this.status == 1 ? "등록됨" : "미등록";
    }
  },
  methods: {
    register() {
      if (_.isUndefined(this.imei) || this.imei.length < 16) {
        console.log('IMEI: the length is more than 16');
        return;
      }
      var re = /^[0-9a-fA-F]+$/;
      if (re.test(this.imei) == false) {
        console.log('IMEI: must be hexadecimal');
        return;
      }
      var data = {
        device_guid: this.device.guid,
        imei: this.imei,
        net_type: this.device.network_type
      }
      this.$store.dispatch('rest/method', {model:'devices', method:'Registration', data:data})
        .then((res) => {
          let msg = {
            name: this.$options.name,
            which: this.which,
            result: res
          }
          this.$emit('hide', msg)
          this.showModal = false
        })
        .catch(err => {
          console.log('RegistDevice::register error:', err.toString())
          this.showModal = false
        })
    },
    cancel() {
      let msg = {
        name: this.$options.name,
        which: this.which,
        data: 'cancel'
      }
      this.$emit('hide', msg);
      this.showModal = false;
    },
    show(device, imei='', status='') {
      this.device = device;
      this.imei = imei;
      this.status = status;
      this.showModal = true;
    }
  }
}
</script>