<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Regist(Upload) Device Dialog"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">{{title}}</h6>
    </template>

    <div class='modal-body'>
      <form name='userForm'>
        <div class='form-group'>
          <div class='row'>
            <div class='col-md-12'>
              <form enctype="multipart/form-data" novalidate>
                <div class="dropbox">
                  <input type="file" name="files" @click="uploadFileName=null" @change="fileChange($event.target.name, $event.target.files);"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" class="input-file">
                    <p v-if="uploadFileName==null">
                      Drag your [device_regi.xlsx] file here to begin<br> or click to browse
                    </p>
                    <p v-if="uploadFileName!=null">
                      <strong>{{ uploadFileName }}</strong> selected ...
                    </p>
                </div>
              </form>
            </div>
          </div>
        </div>

      </form>
    </div>

    <template #footer>
      <CButton @click="uploadFile()" color="info">등록</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

  <Notify ref="notifyDialog"/>

  </KModal>
</template>

<script>
import Vue from 'vue';
import loopback from '@/services/loopback';

export default {
  name: 'UploadDevice',
  data () {
    return {
      showModal: false,
      title: '디바이스 등록(Upload)',
      which: 'upload',
      uploadFileName: null,
    }
  },
  computed: {
  },
  methods: {
    fileChange(fieldName, fileList) {
      var selected = _.first(fileList);
      if (!selected) return;
      this.formData = new FormData();
      fileList.forEach((file) => {
        this.formData.append(fieldName, file, file.name)
      })
      this.uploadFileName = selected.name
      Vue.$log.info('selected file name: ', this.uploadFileName)
    },
    uploadFile() {
      if (this.uploadFileName == null) {
        this.$refs.notifyDialog.show('파일을 선택하세요.');
        return;
      };
      Vue.$log.info('upload file name: ', this.uploadFileName)
      this.$store.dispatch('rest/method', {model:'devices',method:'upload',data:this.formData})
        .then(res => {
          this.$refs.notifyDialog.show(this.uploadFileName + ' : DB 적용 완료')          
          let msg = {
            name: this.$options.name,
            which: this.which,
            result: res
          }
          this.$emit('hide', msg)
          this.showModal = false
        })
        .catch(err => {
          Vue.$log.error('upload error:', err.toString());
          this.$refs.notifyDialog.show(err.toString())
          this.showModal = false
        })
    },
    cancel() {
      let msg = {
        name: this.$options.name,
        which: this.which,
        data: 'cancel'
      }
      this.$emit('hide', msg);
      this.showModal = false;
    },
    show() {
      this.uploadFileName = null;
      this.showModal = true;
    }
  }
}
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 10px 0;
}
</style>