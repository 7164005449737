<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create Site Dialog"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">사이트 {{title}}</h6>
    </template>

    <div class='modal-body'>
      <form name='userForm'>
        <div class='form-group'>
          <div class='row'>
            <div class='col-md-12'>
              <label>사이트명 <strong class='text-danger'>*</strong></label>
              <input type='text' class='form-control' placeholder='사이트명' v-model='site.name' :disabled='isEditMode'>
            </div>
          </div>
        </div>
        <div class='form-group'>
          <div class='row'>
            <div class='col-md-12'>
              <label>주소 <strong class='text-danger'></strong></label>
              <input type='text' class='form-control' placeholder='주소' v-model='site.address'>
            </div>
          </div>
        </div>
        <div class='form-group'>
          <label>전화</label>
          <input type='text' class='form-control' placeholder='전화번호' v-model='site.tel'>
        </div>
        <div class='form-group'>
          <div class='row'>
            <div class='col-md-12'>
              <label>기준 디바이스 <strong class='text-danger'>*</strong><small>(맵 중심 표기용)</small></label>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-12'>
              <select class='form-control' style="height:34px" v-model='site.center_device_guid'>
                <option v-for="device in devices" :value="device.guid" v-bind:key="device.guid">{{device.name}} </option>
              </select>
            </div>
          </div>
        </div>
        <div class='form-group'>
          <label>설명</label>
          <textarea class='form-control' rows='3' placeholder='사이트 설명' v-model='site.desc'></textarea>
        </div>
      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">등록</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

    <Notify ref="notifyDialog"/>
    
  </KModal>
</template>

<script>
import loopback from '@/services/loopback';

export default {
  name: 'CreateSite',
  props: {
    isAdmin: Boolean
  },
  data () {
    return {
      showModal: false,
      title: '생성',
      which: 'create',
      site: {},
      devices: [],
      isEditMode: false
    }
  },
  methods: {
    createSite() {
      console.log('CreateSite::cresteSite: ', this.site)
      var where = {
        name: this.site.name,
        tel: this.site.tel
      }
      this.site.delete = false;

      loopback
        .upsertWithWhere('/sites', where, this.site)
        .then((site) => {
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: site
          }
          this.$emit('hide', msg)
          this.showModal = false
        })
        .catch((err) => {
          console.log('createSite error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
    updateSite() {
      console.log('CreateSite::updateSite: ', this.site)

      var where = {
        guid: this.site.guid
      }
      this.site.delete = false;

      loopback
        .upsertWithWhere('/sites', where, this.site)
        .then((site) => {
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: site
          }
          this.$emit('hide', msg)
          this.showModal = false
        })
        .catch((err) => {
          console.log('createSite error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
    register() {
      this.which === 'create' ? this.createSite() : this.updateSite();
    },
    cancel() {
      let msg = {
        name: this.$options.name,
        which: this.which,
        data: 'cancel'
      }
      this.$emit('hide', msg);
      this.showModal = false;
    },
    show(site, devices) {
      this.site = site;
      this.devices = devices;
      this.title = _.isEmpty(this.site) ? '생성' : '변경'
      this.which = _.isEmpty(this.site) ? 'create' : 'update'
      this.isEditMode = !_.isEmpty(this.site)
      this.showModal = true
    }
  }
}
</script>