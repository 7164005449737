const SITE_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    height: 360,
    striped: true,
    pagination: false,
    pageSize: 10,
    pageList: [5, 10, 25, 50, 100, 200],
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: true,
    singleSelect: false,
    showToggle: false,
    maintainSelected: true,
    sortName: 'name',
    sortOrder: 'desc',
    uniqueId: 'guid',
}

const SITE_TABLE_COLUMS = [
    {
        field: 'state',
        checkbox: true
    }, {
        field: 'name',
        title: '사이트명',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'address',
        title: '주소',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'tel',
        title: '전화번호',
        align: 'center',
        valign: 'top',
        sortable: false
    }, {
        field: 'created_at',
        title: '생성일자',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'desc',
        title: '설명',
        align: 'center',
        valign: 'top',
        sortable: false
    }, {
        field: 'center_device_guid',
        title: 'center_device_guid',
        align: 'center',
        valign: 'top',
        visible: false
    }, {
        field: 'guid',
        title: 'guid',
        align: 'center',
        valign: 'top',
        visible: false
    }
]

const DEVICE_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    height: 360,
    striped: true,
    pagination: false,
    pageSize: 10,
    pageList: [5, 10, 25, 50, 100, 200],
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: true,
    singleSelect: true,
    showToggle: false,
    maintainSelected: true,
    sortName: 'name',
    sortOrder: 'asc',
    uniqueId: 'guid',
}

const DEVICE_TABLE_COLUMS = [
    {
        field: 'state',
        checkbox: true
    }, {
        field: 'site',
        title: '사이트명',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: true
    }, {
        field: 'site_guid',
        title: 'site_guid',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: false
    }, {
        field: 'name',
        title: '디바이스명',
        align: 'left',
        valign: 'top',
        sortable: true,
    }, {
        field: 'address',
        title: '주소',
        align: 'left',
        valign: 'top',
        sortable: true
    }, {
        field: 'sensor',
        title: '최근 측정값',
        align: 'left',
        valign: 'top',
        sortable: false,
    }, {
        field: 'empty',
        title: 'empty',
        align: 'left',
        valign: 'top',
        sortable: false,
        visible: false
    }, {
        field: 'prev_time',
        title: '수신 예상 시간',
        align: 'left',
        valign: 'top',
        sortable: true,
    }, {
        field: 'sensor_time',
        title: '최근 측정시간',
        align: 'left',
        valign: 'top',
        sortable: true,
    }, {
        field: 'guid',
        title: 'guid',
        align: 'center',
        valign: 'top',
        visible: false
    }, {
        field: 'network_type',
        title: '네크워크 종류',
        align: 'center',
        valign: 'top',
        visible: false
    }
]

const SRDS_DEVICE_TABLE_COLUMS = [
    {
        field: 'state',
        checkbox: true
    }, {
        field: 'name',
        title: '디바이스명',
        align: 'center',
        valign: 'top',
        sortable: true,
    }, {
        field: 'address',
        title: '주소',
        align: 'center',
        valign: 'top',
        sortable: true
    }, {
        field: 'pre_sensor',
        title: '최초 기울기',
        align: 'center',
        valign: 'top',
        sortable: true,
    },{
        field: 'sensor',
        title: '측정 기울기',
        align: 'center',
        valign: 'top',
        sortable: true,
    }, {
        field: 'level',
        title: '위험등급',
        align: 'center',
        valign: 'top',
        visible: false
    }, {
        field: 'battery',
        title: '배터리 상태',
        align: 'center',
        valign: 'top',
        visible: true
    },{
        field: 'change_battery_date',
        title: '배터리 교체일자',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'prev_time',
        title: '수신 예상 시간',
        align: 'left',
        valign: 'top',
        sortable: true,
    }, {
        field: 'install_date',
        title: '설치일자',
        align: 'center',
        valign: 'top',
        visible: false,
    }, {
        field: 'sensor_time',
        title: '최근 측정시간',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: true
    },{
        field: 'network_type',
        title: '네크워크 종류',
        align: 'center',
        valign: 'top',
        visible: false
    }
]
const FUMAX_DEVICE_TABLE_COLUMNS = [
    {
        field: 'state',
        checkbox: true
    }, {
        field: 'name',
        title: '디바이스명',
        align: 'center',
        valign: 'top',
        sortable: true,
    }, {
        field: 'address',
        title: '주소',
        align: 'center',
        valign: 'top',
        sortable: true
    }, 
    {
        field: 'deicing',
        title: '제설제 잔량',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false

    }, {
        field: 'battery',
        title: '전압',
        align: 'center',
        valign: 'top',
        visible: true
    },{
        field: 'sensor',
        title: '센서(온/습도)',
        align: 'center',
        valign: 'top',
        sortable: true,
    }, {
        field: 'change_battery_date',
        title: '배터리 교체일자',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'connecting',
        title: '통신상태',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: true 
    }, {
        field: 'activation',
        title: '작동상태',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: true 
    }, {
        field: 'install_date',
        title: '설치일자',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'sensor_time',
        title: '최근 측정시간',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    },{
        field: 'network_type',
        title: '네크워크 종류',
        align: 'center',
        valign: 'top',
        visible: false
    }
]

const SERVICE_TYPES = [
    {
        type: 1,
        name: '토양정보',
        key: 'sis'
    }, {
        type: 2,
        name: '대기정보',
        key: 'air'
    }, {
        type: 3,
        name: '수목보호',
        key: 'tree'
    }, {
        type: 4,
        name: '수질오염',
        key: 'water'
    }, {
        type: 5,
        name: '과수화상',
        key: 'leaf'
    }, {
        type: 6,
        name: '위험감지',
        key: 'srds'
    }, {
        type: 7,
        name: '위험감지(2Camera)',
        key: 'srds.dual'
    }, {
        type: 8,
        name: '산불감지',
        key: 'swfm'
    }, {
        type: 9,
        name: '재선충모니터링',
        key: 'pwdm'
    }
]

const NETWORK_TYPES = [
    {
        type: 1,
        name: 'None'
    }, {
        type: 2,
        name: 'LoRa'
    }, {
        type: 3,
        name: 'NB-IoT'
    }, {
        type: 4,
        name: 'Cat.M1'
    }
]

export {
    SITE_TABLE_OPTIOINS,
    SITE_TABLE_COLUMS,
    DEVICE_TABLE_OPTIOINS,
    SRDS_DEVICE_TABLE_COLUMS,
    FUMAX_DEVICE_TABLE_COLUMNS,
    DEVICE_TABLE_COLUMS,
    SERVICE_TYPES,
    NETWORK_TYPES
}