<template>
<div>
  <CCard>
    <CCardBody class="pt-0">
      <div id="toolbar" class="pt-2">
        <strong class="pl-1">{{title}}({{siteTable.data.length}})</strong>
      </div>
      <v-table 
        id="siteTable" 
        data-toolbar="#toolbar" 
        :columns="siteTable.columns" 
        :data="siteTable.data" 
        :options="siteTable.options" 
        @on-check="onCheckSite" 
        @on-check-all="onCheckSiteAll" 
        @on-uncheck="onUncheckSite"
        @on-uncheck-all="onUncheckSiteAll" 
      ></v-table>
    </CCardBody>
    <CCardFooter>
      <CRow class="text-center">
        <CCol col="12" xl="12">
          <CButton color="warning" class="float-right" @click="openDeleteDialog('site')" v-show="isAdmin" :disabled="permission.delete == false || selected_site == null">
            삭제
          </CButton>
          <CButton color="info" class="float-right mr-1" @click="openDialog('site', 'update')" :disabled="permission.update == false || selected_site == null" >
            수정
          </CButton>
          <CButton color="success" class="float-right mr-1" @click="openDialog('site', 'create')" v-show="isAdmin" :disabled="permission.create == false">
            생성
          </CButton>
        </CCol>
      </CRow>
    </CCardFooter>
  </CCard>

  <CCard>
    <CCardBody class="pt-0">
      <div id="toolbar2" class="pt-2">
        <strong class="pl-1">{{device_name_title}}({{deviceTable.data.length}}) - 디바이스 목록</strong>
      </div>
      <v-table id="deviceTable" data-toolbar="#toolbar2" :columns="deviceTable.columns" :data="deviceTable.data" :options="deviceTable.options" @on-check="onCheckDevice" @on-uncheck="onUncheckDevice"></v-table>
    </CCardBody>
    <CCardFooter>
      <CRow class="text-center">
        <CCol col="12" xl="12">
          <CButton color="warning" class="float-right" @click="openSaveExcel()">엑셀저장</CButton>
          <CButton color="warning" class="float-right mr-1" @click="openRegisterDialog()" v-show="isAdmin" :disabled="permission.create == false || selected_device == null">등록</CButton>
          <CButton color="warning" class="float-right mr-1" @click="openDeleteDialog('device')" v-show="isAdmin" :disabled="permission.delete == false || selected_device == null">삭제</CButton>
          <CButton color="info" class="float-right mr-1" @click="openDialog('device', 'update')" v-show="isAdmin" :disabled="selected_device == null" >수정</CButton>
          <CButton color="success" class="float-right mr-1" @click="openDialog('device', 'create')" v-show="isAdmin" :disabled="permission.create == false">생성</CButton>
          <CButton color="danger" class="float-right mr-1" @click="openUploadDialog()" v-show="isAdmin" :disabled="permission.create == false">업로드</CButton>
        </CCol>
      </CRow>
    </CCardFooter>
  </CCard>

  <Confirm
      ref="confirmDialog"
      title="확인"
      which="delete"
      @hide="hideModal"
      color="warning"
  />
  <Progress
      ref="progressDialog"
      title="확인"
  />
  <CreateSite
      ref="siteDialog"
      :isAdmin.sync= "isAdmin"
      @hide="hideModal"
  />
  <CreateDevice
      ref="deviceDialog"
      :isAdmin.sync= "isAdmin"
      @hide="hideModal"
  />
  <RegistDevice
      ref="registDialog"
      @hide="hideModal"
  />
  <UploadDevice
      ref="uploadDialog"
      @hide="hideModal"
  />

</div>
</template>

<script>
import Vue from 'vue'
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm';
import XLSX from 'xlsx';
import utils from '@/services/utils';
import { SITE_TABLE_OPTIOINS, SITE_TABLE_COLUMS, DEVICE_TABLE_OPTIOINS, DEVICE_TABLE_COLUMS } from "@/constants/siteSettings";
import CronParser from 'cron-parser'

import Progress from '@/views/popups/Progress';
import CreateSite from '@/views/site/CreateSite';
import CreateDevice from '@/views/site/CreateDevice';
import RegistDevice from '@/views/site/RegistDevice';
import UploadDevice from '@/views/site/UploadDevice';

const PERMITTED = 'user';

export default {
  name: 'SiteManage',
  components: {
    BootstrapTable: BootstrapTable,
    Progress: Progress,
    CreateSite: CreateSite,
    CreateDevice: CreateDevice,
    RegistDevice: RegistDevice,
    UploadDevice: UploadDevice
  },
  props: {
    title: {
      type: String,
      default: '사이트 관리'
    },
    prefix: {
      type: String,
      default: ''
    }
  },
  created() {
    SITE_TABLE_OPTIOINS.search = true;
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
    this.group = this.userInfo.group;
    this.group_list = this.userInfo.group_list;
    this.sites = this.userInfo.sites;

    // this.user = this.$store.state.auth.user_info.user;
    // this.group = this.$store.state.auth.user_info.group;
    // this.group_list = this.$store.state.auth.user_info.group_list;
    // this.sites = this.$store.state.auth.user_info.sites

    this.selected_site_guid = this.$route.params.id;
    this.active_site_name = _.get(this.user, "active_site_name", "테크나인");

    if (this.isAdmin) {
      this.siteTable.options.showColumns = this.deviceTable.options.showColumns = false;
    }
    _.find(this.siteTable.columns, { field: 'created_at' }).formatter = this.createFormatter;

    _.find(this.deviceTable.columns, { field: 'name' }).formatter = this.nameFormatter;
    _.find(this.deviceTable.columns, { field: 'prev_time' }).formatter = this.dateFormatter2;
    _.find(this.deviceTable.columns, { field: 'sensor_time' }).formatter = this.dateFormatter;
    _.find(this.deviceTable.columns, { field: 'sensor' }).formatter = this.sensorFormatter;

    var filtered = this.group.role.filter(function(e) {
      return e.type == PERMITTED;
    })[0];
    this.permission = filtered.permission

    this.getBaseInfo()
  },
  mounted() {
    // setTimeout(function() {
    //   $('#deviceTable').bootstrapTable('check', 0)
    // }, 100)
  },
  data () {
    return {
      isAdmin: this.$store.state.auth.is_admin,
      user: {},
      sites: [],
      site_guid: '',
      sensor_type: [],
      permission: {},
      siteTable: {
        options: SITE_TABLE_OPTIOINS,
        columns: SITE_TABLE_COLUMS,
        data: []
      },
      deviceTable: {
        options: DEVICE_TABLE_OPTIOINS,
        columns: DEVICE_TABLE_COLUMS,
        data: []
      },
      selected_site: null,
      selected_device: null,
    }
  },
  computed: {
    device_name_title() {
      return this.selected_site ? this.selected_site.name : this.user.realm;
    }
  },
  methods: {
    getDeviceUrl(row) {
      return '#/{0}device/{1}'.format(this.prefix, _.get(row, "guid"));
    },
    nameFormatter(value, row, index) {
      return "<a href='" + this.getDeviceUrl(row) + "'>" + _.get(row, "name") + "</a>";
    },
    createFormatter(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    dateFormatter(value, row, index) {
      if (!_.get(row, "empty", false)) {
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      } else {
        return "-"
      }
    },
    dateFormatter2(value, row, index) {
      if (_.get(row, "empty", false))
        return "-";
      var date_string = moment(value).format('YYYY-MM-DD HH:mm');
      if (new Date(value).getTime() < new Date(row.sensor_time).getTime())
        return date_string;
      else
        return '<span style="color: red;">' + date_string + '</span>'
    },
    sensorFormatter(value, row, index) {
      if (_.isUndefined(value))
        return
      var self = this
      var result = "";
      value.forEach(function(v) {
        if (!_.get(row, "empty", false)) {
          if (v.id === 0) v.id = 1;
          var description = _.find(self.sensor_type, {
            code: v.type,
            id: v.id
          });
          if (!description) return
          var desc = description.value + ':' + v.value + description.unit + ' ';
          if (v.type === 10) {
            if (self.isAdmin)
              result += desc
          } else {
            result += desc
          }
        } else {
          result = "측정값 없음"
        }
      })
      return "<a href='" + this.getDeviceUrl(row) + "'>" + result + "</a>";
    },
    deleteSite() {
      Vue.$log.info('SiteManage::deleteSite: ', this.selected_site)
      let where = {
        guid: this.selected_site.guid
      }
      loopback
        .upsertWithWhere('/sites', where, { delete: true })
        .then((res) => {
          this.deviceTable.data = [];
          $('#siteTable').bootstrapTable('uncheckAll');
          this.getBaseInfo()
        })
        .catch((err) => {
          Vue.$log.error('SiteManage::deleteSite error: ', err.toString())
        })
    },
    deleteDevice() {
      Vue.$log.info('SiteManage::deleteDevice: ', this.selected_device)
      let where = {
        guid: this.selected_device.guid
      }
      loopback
        .upsertWithWhere('/devices', where, { status: -1 })
        .then((res) => {
          // $('#deviceTable').bootstrapTable('remove', { field: 'guid', values: this.selected_device.guid});
          // this.deviceTable.data = $('#deviceTable').bootstrapTable('getData');
          // _.remove(this.deviceTable.data, {'guid': this.selected_device.guid}); // odd : not working
          this.deviceTable.data = _.filter(this.deviceTable.data, device => device.guid != this.selected_device.guid);
          this.getBaseInfo()
        })
        .catch((err) => {
          Vue.$log.error('SiteManage::deleteDevice error: ', err.toString())
        })
    },
    hideModal(params) {
      Vue.$log.debug('UserManage::hideModal params:', params);
      if (_.isUndefined(params) || params.data === 'cancel') {
        return; // cancel command
      }
      switch (params.name) {
        case 'Confirm':
          if (params.data !== 'confirm')
            break;
          if (params.which === 'site') {
            this.deleteSite()
          } else {
            this.deleteDevice()
          }
          break;
        case 'CreateSite':
          this.getBaseInfo()
          break;
        case 'CreateDevice':
          this.getDeviceList(this.selected_site ? this.selected_site.guid : this.selected_site_guid);
          break;
        case 'UploadDevice':
          this.getDeviceList(this.selected_site ? this.selected_site.guid : this.selected_site_guid);
          break;
        default:
          break
      }
    },
    updateStatus(selected) {
      if (selected) {
        this.selected_site = selected;
        this.user.active_site_name = selected.name;
      } else {
        this.selected_site = null;
      }
      utils.setStorage("userInfo", this.userInfo);

      this.selected_device = null;
      $('#deviceTable').bootstrapTable('uncheckAll');
      this.deviceTable.data = $('#deviceTable').bootstrapTable('getData');
    },
    onCheckSite(row, $element) {
      this.getDeviceList(row.guid)
        .then(r => this.updateStatus(row))
    },
    onUncheckSite(row, $element) {
      this.selected_device = null;
      $('#deviceTable').bootstrapTable('remove', { field: 'site_guid', values: row.guid});

      var selected = _.first($('#siteTable').bootstrapTable('getSelections'));
      this.updateStatus(selected)
    },
    onCheckSiteAll(after, before) {
    },
    onUncheckSiteAll(after, before) {
      this.selected_site = null;
      this.selected_device = null;
      this.deviceTable.data = [];
    },
    onCheckDevice(row, $element) {
      this.selected_device = row;
    },
    onUncheckDevice(row, $element) {
      this.selected_device = null;
    },
    openRegisterDialog() {
      Vue.$log.debug('SiteManage::openRegisterDialog called')
      var self = this
      var filter = {
        where: {
          device_serial: this.selected_device.guid
        }
      }
      this.$store.dispatch('rest/find', {model:'device_registrations', filter:filter})
        .then((info) => {
          if (_.isEmpty(info)) {
            Vue.$log.warn('regi info does not exist: ', self.selected_device.guid)
            return
          }
          info = _.first(info)
          self.$refs.registDialog.show(self.selected_device, info.imei, info.status)
        })
    },
    openDeleteDialog(type) {
      let selected_row = type === 'site' ? this.selected_site : this.selected_device;
      if (selected_row === null) {
        Vue.$log.warn('ERROR: you must be select an item');
        return
      }
      let type_string = type === 'site' ? '사이트' : '디바이스';
      let message = '{0} : "{1}" 을(를) 삭제 하시겠습니까?'.format(type_string, selected_row.name);
      this.$refs.confirmDialog.show(message, type_string + ' 삭제', undefined, type);
    },
    openDialog(type, which) {
      let selected_row = type === 'site' ? this.selected_site : this.selected_device;
      if (which === 'update' && !selected_row) {
        Vue.$log.warn('ERROR: you must be select an item');
        return
      }
      if (selected_row) {
        delete selected_row.group_name
      }
      let data = which === 'create' ? {} : _.cloneDeep(selected_row);
      if (type === 'site') {
        var datas = $('#deviceTable').bootstrapTable('getData');
        var devices = _.filter(datas, { 'site_guid' : this.selected_site.guid });
        this.$refs.siteDialog.show(data, devices);
      } else {
        this.$refs.deviceDialog.show(data, this.sites);
      }
    },
    openUploadDialog() {
      this.$refs.uploadDialog.show()
    },
    getBaseInfo() {
      var self = this;
      this.selected_row = null;
      utils.loadSensorTypes(function(sensor_type) {
        self.sensor_type = sensor_type
      });

      var getFilter = function() {
        let site_guids = []
        let filter = {
          where: {
            and: [{
              delete: false
            }, {
              guid: {
                inq: site_guids
              }
            }]
          }
        }
        if (self.isAdmin) {
          return self.$store.dispatch('rest/find', { model: 'sites', filter: { where: { delete : false }, fields: { guid: true } } })
            .then((r) => {
              r.forEach(site => site_guids.push(site.guid));
              return Promise.resolve(filter);
            })
            .catch((err) => {
              return Promise.reject(err);
            })
        } else {
          self.sites.forEach(site => site_guids.push(site.guid));
          return Promise.resolve(filter);
        }
      }
      
      getFilter().then((filter) => {
        return this.$store.dispatch('rest/find', { model: 'sites', filter: filter })
      }).then((res) => {
          var rows = []
          res.forEach(e => {
            rows.push({
              name: e.name,
              address: e.address,
              tel: e.tel,
              desc: e.desc,
              created_at: e.created_at,
              center_device_guid: e.center_device_guid,
              guid: e.guid
            })
          })
          this.siteTable.data = rows;

          setTimeout(function() {
            var data = $('#siteTable').bootstrapTable('getData');
            var index = self.selected_site_guid ? 
              _.findIndex(data, {guid: self.selected_site_guid}) :
              _.findIndex(data, {name: self.active_site_name});
            if (index < 0) return;
            $('#siteTable').bootstrapTable('scrollTo', { unit: 'rows', value: index });
            $('#siteTable').bootstrapTable('check', index)
          }, 100)
        })
    },
    getDeviceList(site_guid, excel) {
      var save = excel | false;
      var data = {
        site_guid: [site_guid]
      }
      return loopback
        .method('sites', 'getDeviceList', data)
        .then(res => {
          this.site_guid = site_guid;
          if (_.isEmpty(res)) {
            return Promise.resolve(res)
          }
          var rows = []
          var deviceData = $('#deviceTable').bootstrapTable('getData');
          res.forEach(d => {
            d.data.forEach(e => {
              var interval = CronParser.parseExpression(e.upload_cron)
              var data = {
                site: d.Site.name,
                site_guid: d.Site.guid,
                name: e.name,
                address: e.address,
                sensor: e.sensor.data,
                pre_sensor: e.standard,
                empty: e.sensor.empty,
                sensor_time: e.sensor.created_at,
                prev_time: new Date(interval.prev()),
                install_date: e.install_date,
                change_battery_date: e.change_battery_date,
                guid: e.guid,
                network_type: e.network_type
              }
              if (_.has(e, 'powering_cron') && _.get(e, 'water_level', -1) !== -1) {
                data['water_level'] = e.water_level
              }
              if (_.has(e, 'nutrient_cron') && _.get(e, 'nutrient_level', -1) !== -1) {
                data['nutrient_level'] = e.nutrient_level
              }
              rows.push(data);
              if (_.findIndex(deviceData, { 'guid': data.guid }) == -1)
                this.deviceTable.data.push(data);
                // $('#deviceTable').bootstrapTable('prepend', data);
            });
          })
          return Promise.resolve(rows)
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    openSaveExcel() {
      var self = this;
      var write = function() {
        var siteData = self.siteTable.data;
        var r = [];
        var wscols = [{
            wch: 20
          },
          {
            wch: 15
          },
          {
            wch: 50
          },
          {
            wch: 25
          }
        ];

        var wb = {
          Sheets: {},
          Props: {},
          SSF: {},
          SheetNames: []
        }

        var results = [];

        var wscols = [{
          wch: 20
        }, {
          wch: 20
        }, {
          wch: 50
        }];

        siteData.forEach(function(data, i) {
          self.getDeviceList(data.guid, true).then(function(result) {
            var r = [];
            result.forEach(function(data, idx) {
              var row = {
                "디바이스명": data.name,
                "디바이스 아이디": data.guid,
                "주소": data.address
              }
              r.push(row);
            })

            results.push({
              site: data.name,
              devices: r
            })
          }).then(function(r) {
            if (results.length == siteData.length) {
              results.forEach(function(data, i) {
                var ws = XLSX.utils.json_to_sheet(data.devices);
                ws['!cols'] = wscols;
                wb.SheetNames.push(data.site);
                wb.Sheets[data.site] = ws;
                if (i == results.length - 1) {
                  XLSX.writeFile(wb, "사이트별_디바이스" + ".xlsx");
                }
              })
            }
          })
        });
      }

      this.$refs.progressDialog.show({message: '요청 데이터를 저장중입니다.', counter:50, callback: write})
    }
    
  }
}
</script>